(function (angular, undefined) {
    "use strict";
    var projects = angular.module("mikesProjects");

    projects.config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state("projects", {
                parent: "mike's",
                url: "/projects",
                data: {
                    pageTitle: "Mike's Projects"
                },
                views: {
                    "": {
                        templateUrl: "app/projects/templates/projects.html",
                        controller: "mikesProjects.ctrl",
                        controllerAs: "vm"
                    }
                }
            });
    }]);

})(angular);
